<template>
  <div class="conference-page">
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item> 分会信息 </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="news-wrapper">
      <div class="news-bar">
        <div class="bar-title">
          <img src="../assets/images/hygl.png" alt="" />
          <span>分会信息</span>
        </div>
        <ul class="bars" @click="changeList">
          <li class="bars-item" data-tid="1">分会介绍</li>
          <li class="bars-item" data-tid="2">分会章程</li>
          <li class="bars-item" data-tid="3">服务内容</li>
          <li class="bars-item" data-tid="4">实验室</li>
          <li class="bars-item" data-tid="5">联系我们</li>
        </ul>
      </div>
      <div class="news-list">
        <h3>
          <span>{{ barTitle }}</span>
        </h3>
        <div class="news-details" v-if="info">
          <h4 class="detail-title">{{ info.title }}</h4>
          <h6 class="times">
            <span>来源 : {{ info.source }}</span> &emsp;
            <span>日期 : {{ info.dates }}</span>
          </h6>
          <div v-html="info.infos" style="font-size: 16px;color: #333333;"></div>
        </div>
        <div
          class="news-details"
          v-if="!info"
          style="text-align: center; font-size: 20px"
        >
          暂无详细信息
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      barTitle: "分会介绍",
      info: {},
    };
  },

  components: {},

  computed: {},
  mounted() {
    this.getInfos();
  },
  methods: {
    changeList(e) {
      if (e.target.tagName !== "LI") return;
      this.barTitle = e.target.innerText;
      this.getInfos(e.target.dataset.tid);
    },
    async getInfos(tid = 1) {
      let { data } = await this.axios.get(`/index/branch_list?type_id=${tid}`);
      this.info = data.list;
    },
  },
};
</script>
<style lang="scss" scoped>
.conference-page {
  .news-wrapper {
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 30px;
    display: flex;
    padding-bottom: 230px;
    .news-bar {
      width: 230px;
      max-height: 370px;

      padding: 24px 20px 60px;
      background-color: #0168ad;
      color: #b1daf5;

      box-sizing: border-box;
      .bar-title {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-left: 10px;
        }
      }
      .bars {
        width: 190px;
        list-style: none;
        padding: 0;
        margin: 15px auto 0;
        display: flex;
        flex-direction: column;
        user-select: none;
        .bars-item {
          width: 100%;
          height: 38px;
          line-height: 38px;
          margin-top: 10px;
          color: #333;
          background-color: #fff;
          cursor: pointer;
          text-align: center;
          &:hover {
            color: #fff;
            background-color: #e8291d;
          }
        }
      }
    }
    .news-list {
      width: calc(1200px - 230px);
      height: 100%;

      padding: 20px;
      box-sizing: border-box;
      h3 {
        margin: 0;

        font-size: 18px;
        font-weight: normal;
        border-bottom: 3px solid #e2e2e2;
        padding: 5px;
        box-sizing: border-box;
        span {
          position: relative;
          &::after {
            content: "";
            display: block;
            position: absolute;
            left: -5px;
            bottom: -7px;
            width: 115%;
            height: 3px;
            background-color: #125e9e;
          }
        }
      }
      .news-details {
        // margin-top: 30px;
        padding: 20px 40px;
        box-sizing: border-box;
        h4 {
          font-size: 18pt;
          text-align: center;
          margin: 0;
          margin-top: 10px;
          padding-bottom: 20px;
          border-bottom: 1px dotted #000;
        }
        .times {
          font-size: 14px;
          text-align: center;
          margin: 10px;
          font-weight: normal;
          color: rgb(12, 141, 227);
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-pagination {
  text-align: center;
  margin-top: 30px;
  .btn-prev,
  .btn-next {
    background-color: #fff !important;
    border: 1px solid #66a4ff;
    border-radius: 3px !important;
    padding: 5px 10px !important;
    box-sizing: border-box !important;
    &:hover {
      color: #66a4ff;
    }
    span {
      line-height: 1;
    }
  }
  .el-pager {
    li {
      font-weight: normal;
    }
  }
}
</style>
